import { Box, Img, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import aboutus from "./Assets/aboutus.png";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box color={"white"} bgColor={"white"} fontFamily={"Roboto Condensed"}>
      <NavBar />
      <Box color={"#033053"} className={"pt-2 h-full"}>
        <Box
          className={
            "flex flex flex-col pt-20 md:pt-26 grid-cols-1 md:grid-rows-2 h-auto md:grid-cols-1"
          }
        >
          <Box
            className={"flex flex-col md:flex-row h-auto mx-5 pt-10 md:mx-20"}
          >
            <Box
              flexDir={"column"}
              className={
                "py-1 h-auto flex flex-col align-middle justify-center"
              }
            >
              <Box
                className={
                  "text-start md:text-start my-3 p-2 mx-auto flex flex-col align-middle justify-center"
                }
              >
                <Text
                  fontWeight={"600"}
                  fontFamily={"Roboto Condensed"}
                  className={"text-2xl my-2 px-10"}
                >
                  About Us
                </Text>
                <Text
                  fontFamily={"Roboto Condensed"}
                  className={"text-xl my-auto px-10 py-0 "}
                >
                  {" "}
                  Ndala ICT Solutions (Pty) Ltd was established in 2015. We are
                  headquartered in Bryanston, Johannesburg. We are a 100% black
                  owned, and 30% women owned QSE, and a B-BBEE Level 1
                  contributor. We are a Managed Services Provider that offers
                  end-to-end solutions across a wide range of ICT Services and
                  Security Systems, to small, medium and large enterprises in
                  both private and public sectors.
                </Text>
              </Box>
            </Box>
            <Box h={"fit-content"} className={"hidden md:block"}>
              <Img src={aboutus} height={"auto"} />
            </Box>
          </Box>
          <Box
            className={
              "flex flex-col md:flex-row  h-fit md:grid-cols-3 py-10 px-auto mx-20 "
            }
          >
            <Box
              className={" h-auto p-10 text-start "}
              _hover={{ boxShadow: "md", bgColor: "gray.200" }}
            >
              <Text
                fontWeight={"600"}
                fontFamily={"Roboto Condensed"}
                className={"text-2xl my-2 mx-0"}
              >
                Our Vision
              </Text>
              <Text fontFamily={"Roboto Condensed"} className={"text-xl"}>
                To be the trusted ICT Solutions Provider.
              </Text>
            </Box>
            <Box
              className={"h-auto p-10 text-start "}
              _hover={{ boxShadow: "md", bgColor: "gray.200" }}
            >
              {" "}
              <Text
                fontWeight={"600"}
                fontFamily={"Roboto Condensed"}
                className={"text-2xl my-2 mx-0"}
              >
                Our Values
              </Text>
              <Text fontFamily={"Roboto Condensed"} className={"text-xl"}>
                Customer Care, Trust, Respect, Innovation, Commitment, and
                Sustainability.
              </Text>
            </Box>
            <Box
              className={"h-fit text-start p-10"}
              _hover={{ boxShadow: "md", bgColor: "gray.200" }}
            >
              <Text
                fontWeight={"600"}
                fontFamily={"Roboto Condensed"}
                className={"text-2xl my-2 mx-0"}
              >
                Our Mission
              </Text>
              <Text fontFamily={"Roboto Condensed"} className={"text-xl"}>
                We strive to build long-term relationships with our clients and
                solution partners by creating value that lasts.
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default AboutUs;
