import { BiSupport } from "react-icons/bi";
import OwlCarousel from "react-owl-carousel2";
import "../node_modules/react-owl-carousel2/lib/styles.css";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Img,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import ValuesSVG from "./components/ValuesSVG";
import React, { useEffect } from "react";
import Lenis from "@studio-freight/lenis";
import gsap from "gsap";
import { Parallax } from "react-parallax";
import "./HomePage.scss";
import { Link } from "react-router-dom";
import {
  IoBagHandleSharp,
  IoChatbubblesOutline,
  IoShieldCheckmarkOutline,
  IoWifi,
} from "react-icons/io5";
import ciscologo from "./Assets/ciscologo.png";
import CRSlogo from "./Assets/CRSlogo.jpg";
import Crestronlogo from "./Assets/Crestronlogo.jpg";
import ControlLogo from "./Assets/ControlLogo.jpg";
import dellemclogohd from "./Assets/dellemclogohd.png";
import liquidtelecom from "./Assets/LiquidTelecom_Logo.jpg";
import axiz from "./Assets/axiz.png";
import kathealogo from "./Assets/kathealogo.png";
import mtn from "./Assets/Newmtnlogo.jpg";
import polycom from "./Assets/PolycomLogo.png";
import fortnet from "./Assets/Fortinetlogo.png";
import huawei from "./Assets/huaweilogo.png";
import tod from "./Assets/tod_logo.png";
import zoom from "./Assets/Zoom_Logo_Blue.png";
import land1 from "./Assets/1363031084-huge.jpg";
import land2 from "./Assets/land2.jpg";
import land3 from "./Assets/land3.jpg";
import abstract3 from "./Assets/landingVid.mp4";
import billboard2 from "./Assets/billboard2.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./slider-animations.css";
import "react-animated-slider/build/horizontal.css";

gsap.registerPlugin(ScrollTrigger);

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const lenis = new Lenis({
    duration: 1.2,
    easing: (t) => Math.min(1, 1001 - Math.pow(2, -10 * t)),
    smoothWheel: true,
    smoothTouch: false,
    touchMultiplier: 2,
  });

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);
  lenis.on("scroll", () => {
    //console.log(sections.length)
  });
  const options = {
    items: 1,
    nav: false,
    rewind: true,
    // autoplay: true,
    loop: true,
    center: true,
  };
  return (
    <Box
      height={"100%"}
      pos={"relative"}
      color={"white"}
      bgColor={"blackAlpha.400"}
      fontFamily={"Roboto Condensed"}
    >
      <NavBar />
      <Box id={"home"} width={"100%"}>
        <Box className={"p-10"}></Box>
        <Box className={"h-full"} pos={"relative"}>
          <Box height={"100vh"}>
            <OwlCarousel
              options={options}
              style={{ height: "120vh", overflow: "hidden" }}
            >
              <Box height={"100vh"} pos={"relative"} width={"100%"}>
                <Box className={"w-full"} h={"inherit"}>
                  <video
                    width={"auto"}
                    style={{ objectFit: "fill", height: "130%" }}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    id={"videoDisplay"}
                  >
                    <source src={abstract3} type="video/mp4"></source>
                    Your browser does not support the video tag.
                  </video>
                </Box>
                <Flex
                  flexDir={"column"}
                  pos={"absolute"}
                  bgColor={"#00060"}
                  left={0}
                  right={0}
                  top={0}
                  bottom={0}
                >
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    bgColor={"#00000070"}
                    className={
                      "text-center justify-start pt-40 md:p-0 md:justify-center m-auto align-middle h-full  flex flex-col w-full"
                    }
                  >
                    <Text
                      bgColor={"whiteAlpha.900"}
                      className={
                        "mx-auto my-0  text-bold text-4xl md:text-6xl w-full"
                      }
                      color={"transparent"}
                      bgClip={"text"}
                    >
                      Welcome to Ndala ICT Solutions{" "}
                    </Text>
                    <Text className={"text-lg text-center  md:text-2xl"}>
                      We strive to build long-term relationships with our
                      clients and solution partners by creating value that
                      lasts.
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Box height={"100vh"} pos={"relative"} width={"auto"}>
                <Img
                  src={land2}
                  height={"100%"}
                  width={"auto"}
                  objectFit={"cover"}
                  alt="The Last of us"
                />
                <Flex
                  flexDir={"column"}
                  pos={"absolute"}
                  bgColor={"#00090"}
                  left={0}
                  right={0}
                  top={0}
                  bottom={0}
                >
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    className={" h-full"}
                  >
                    <Flex
                      alignItems={"center"}
                      className={
                        "mx-auto justify-start md:justify-center  pt-40 md:p-2 md:m-auto h-full w-fit  flex justify-start flex-col"
                      }
                    >
                      <Text
                        className={
                          " m-2 p-1 md:p-10 w-full md:w-3/4 text-xl md:text-2xl lg:text-4xl text-center"
                        }
                        border={"1px solid"}
                        borderColor={"rgba(255,255,255,0.88)"}
                        fontWeight={"400"}
                        backdropFilter={"blur(5px)"}
                        bgColor={"rgba(255,255,255,0.8)"}
                        bgClip={"text"}
                        fontFamily={"Roboto Condensed"}
                      >
                        {" "}
                        Whether you need to improve communication, enhance
                        security, or automate key processes, our
                        state-of-the-art ICT solutions can help you achieve your
                        goals.
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
              <Box
                height={"100vh"}
                pos={"relative"}
                width={"auto"}
                objectcover={"cover"}
              >
                <Img
                  height={"100%"}
                  width={"auto"}
                  objectFit={"cover"}
                  src={land3}
                  alt="Servers"
                />
                <Flex
                  flexDir={"column"}
                  pos={"absolute"}
                  left={0}
                  right={0}
                  top={0}
                  bottom={0}
                  bgColor={"rgba(26,26,26,0.6)"}
                >
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    className={" h-full w-full"}
                  >
                    <Flex
                      className={
                        "text-center justify-start pt-40 md:p-0 md:justify-center md align-middle h-full  flex flex-col w-full"
                      }
                    >
                      <Text
                        className={"m-1 md:m-4 text-3xl text-center"}
                        fontWeight={"400"}
                      >
                        Don't let IT issues hold your business back.
                      </Text>
                      <Text
                        className={"text-center m-1 md:m-2"}
                        lineHeight={"20px"}
                      >
                        {" "}
                        Contact us today to learn more about our Managed IT
                        Services and how we can help your business succeed.{" "}
                      </Text>
                      <Link to={"/contactUs"}>
                        <Button
                          bgColor={"#033053"}
                          _hover={{ bgColor: "#04477e" }}
                          borderRadius={"0"}
                        >
                          {" "}
                          Contact US
                        </Button>
                      </Link>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </OwlCarousel>
          </Box>
          <Box
            className={"absolute  bottom-5 md:bottom-1 left-0 right-0"}
            zIndex={20}
          >
            <Center>
              <Tabs
                isFitted
                bgColor={"transparent"}
                className={"w-auto m-4"}
                width="95%"
                height={"100%"}
                data-aos="zoom-in-right"
              >
                <TabList className={"py-4"} border={"none"}>
                  <Tab
                    bgColor={"#033053ff"}
                    className={"m-2 h-full"}
                    borderRadius={"20px"}
                    _selected={{ bgColor: "white", color: "#033053" }}
                    data-aos="zoom-in-right"
                  >
                    <Icon as={BiSupport}></Icon>
                    <Text
                      className={
                        "p-0 md:px-1 hidden md:block  text-xs sm:text-sm md:text-xs lg:text-xs text-start"
                      }
                    >
                      IT Support Services{" "}
                    </Text>
                  </Tab>
                  <Tab
                    className={"m-2"}
                    bgColor={"#033053ff"}
                    borderRadius={"20px"}
                    _selected={{ bgColor: "white", color: "#033053" }}
                    height={"100%"}
                    data-aos="zoom-in"
                  >
                    <Icon as={IoWifi}></Icon>
                    <Text
                      className={
                        "p-0 md:px-1 hidden md:block  text-xs sm:text-sm md:text-xs lg:text-xs text-start"
                      }
                    >
                      Networking Services{" "}
                    </Text>
                  </Tab>
                  <Tab
                    className={"m-2"}
                    bgColor={"#033053ff"}
                    borderRadius={"20px"}
                    _selected={{ bgColor: "white", color: "#033053" }}
                    height={"100%"}
                    data-aos="zoom-in"
                  >
                    <Icon as={IoChatbubblesOutline}></Icon>
                    <Text
                      className={
                        "p-0 md:px-1 hidden md:block text-xs sm:text-sm md:text-xs lg:text-xs text-start"
                      }
                    >
                      Collaboration & Communication
                    </Text>
                  </Tab>
                  <Tab
                    className={"m-2"}
                    bgColor={"#033053ff"}
                    borderRadius={"20px"}
                    _selected={{ bgColor: "white", color: "#033053" }}
                    data-aos="zoom-in"
                  >
                    <Icon as={IoShieldCheckmarkOutline}></Icon>
                    <Text
                      className={
                        "p-0 md:px-1 hidden md:block text-xs sm:text-sm md:text-xs lg:text-xs text-start"
                      }
                    >
                      Cyber Security
                    </Text>
                  </Tab>
                  <Tab
                    className={"m-2"}
                    bgColor={"#033053ff"}
                    borderRadius={"20px"}
                    _selected={{ bgColor: "white", color: "#033053" }}
                    data-aos="zoom-in"
                  >
                    <Icon as={IoBagHandleSharp}></Icon>
                    <Text
                      className={
                        "p-0 md:px-1 hidden md:block text-xs sm:text-sm md:text-xs lg:text-xs text-start"
                      }
                    >
                      Hardware & Software Sales
                    </Text>
                  </Tab>
                </TabList>
                <TabPanels bgColor={"white"} className={"grid"}>
                  <TabPanel className={"px-2 py-4  h-full"}>
                    <Text color={"black"} className={"p text-xl text-center"}>
                      {" "}
                      IT Support Services
                    </Text>
                    <Text color={"black"} className={"p-4 text-center h-auto"}>
                      Our IT Support Services provide reliable,innovative and
                      efficient solutions to keep your business running
                      smoothly. From network maintenance to data backup and
                      security, we've got you covered.
                    </Text>
                  </TabPanel>
                  <TabPanel className={"px-2 py-4"}>
                    <Text
                      color={"black"}
                      className={"px-4 text-xl text-center"}
                    >
                      {" "}
                      Networking Services
                    </Text>
                    <Text color={"black"} className={"p-4 text-center h-full"}>
                      Our Networking Services help businesses connect and
                      communicate efficiently.Through innovation and expert
                      team, we offer tailored solutions to enhance your network
                      security and performance.
                    </Text>
                  </TabPanel>
                  <TabPanel className={"px-2 py-4"}>
                    <Text
                      color={"black"}
                      className={"px-4 text-xl text-center"}
                    >
                      {" "}
                      Collaboration & Communication
                    </Text>
                    <Text color={"black"} className={"p-4 text-center h-auto"}>
                      Our Collaboration & Communication Services enable
                      businesses to improve teamwork and productivity by
                      providing tools such as video conferencing, VoIP,Audio and
                      Visual Technology.
                    </Text>
                  </TabPanel>
                  <TabPanel className={"px-2 py-4 h-auto"}>
                    <Text
                      color={"black"}
                      className={"px-4 text-xl text-center"}
                    >
                      {" "}
                      Cyber Security
                    </Text>
                    <Text color={"black"} className={"p-4 text-center h-full"}>
                      Protect your business from cyber threats with our
                      comprehensive cybersecurity solutions. Our expert team
                      uses the latest technologies to safeguard your data,
                      systems, and reputation.
                    </Text>
                  </TabPanel>
                  <TabPanel className={"px-2 py-4 h-full"}>
                    <Text
                      color={"black"}
                      className={"px-4 text-xl text-center"}
                    >
                      {" "}
                      Hardware & Software Sales
                    </Text>
                    <Text color={"black"} className={"p-4 text-center"}>
                      We are excited to present our latest cutting-edge hardware
                      and software products, designed to enhance productivity,
                      streamline operations, and elevate your digital experience
                      like never before
                    </Text>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Center>
          </Box>
        </Box>
      </Box>
      <Box id={"aboutUs"}>
        <Center height={"90vh"}>
          <Box
            className={"grid grid-cols-1 md:grid-cols-2"}
            h={"100%"}
            backdropFilter={"blur(1px)"}
          >
            <Center
              flexDir={"column"}
              className={"w-full"}
              h={"100%"}
              backFilter={"blur(10px)"}
              bgColor={"#033053"}
            >
              <Text
                fontFamily={"Roboto Condensed"}
                className={"text-3xl m-5 text-start "}
              >
                ABOUT US{" "}
              </Text>
              <Box className={"m-6 md:m-20 "}>
                <Text
                  fontFamily={"Roboto Condensed"}
                  lineHeight={"2em"}
                  textAlign={"start"}
                  className={"text-md md:text-lg"}
                >
                  Ndala ICT Solutions (Pty) Ltd was established in 2015. We are
                  headquartered in Bryanston, Johannesburg. We are a 100% black
                  owned, and 30% women owned QSE, and a B-BBEE Level 1
                  contributor. We are a Managed Services Provider that offers
                  end-to-end solutions across a wide range of ICT Services and
                  Security Systems, to small, medium and large enterprises in
                  both private and public sectors.
                </Text>
              </Box>
            </Center>
            {window.innerWidth > 786 ? (
              <Center className={"hidden md:block"}>
                <Img
                  className={"hidden md:block"}
                  src={billboard2}
                  objectFit={"cover"}
                  width={"auto"}
                  height={"100%"}
                />
              </Center>
            ) : (
              ""
            )}
          </Box>
        </Center>
      </Box>
      <Box>
        <Parallax
          blur={0}
          bgImage={land1}
          bgClassName={"object-cover md:object-fill"}
          bgImageAlt={"NdalaICT Landing Page Image"}
          strength={-100}
        >
          <ValuesSVG />
        </Parallax>
      </Box>
      <Box id={"solutions"} h={"100%"} bgColor={"#033053"}>
        <Box
          className={
            "grid grid-cols-1 md:grid-cols-2 text-blue-800 m-auto py-20 px-10 "
          }
          h={"90%"}
          fontFamily={"Roboto Condensed "}
        >
          <Box bgColor={"#D9D9D9"} className={"m-0 md:ml-5"} h={"100%"}>
            <Text className={"m-2 p-5 text-3xl"}>Managed Services</Text>
            <Box
              className={"text-start m-3 md:m-20 text-large"}
              lineHeight={"2em"}
            >
              <Text className={"m-4"}>
                Managing large IT operations can be complicated especial in the
                era of constantly changing business needs.
              </Text>
              <Text className={"m-4"}>
                With our IT experts who have more than 15 years of experience,
                we are able to provide services that enables your organization
                to focus on business operations as we handle your daily IT
                operations.
              </Text>
              <Text className={"m-4"}>
                From setting up your IT infrastructure to managing your digital
                communications with your clients and share holders.
              </Text>
            </Box>
          </Box>
          <Box bgColor={"#002542"} color={"whitesmoke"}>
            <Text className={"m-2 p-5 text-3xl"}>
              Pay as you Go Tech Support{" "}
            </Text>
            <Box
              className={"text-start m-4  md:m-20 text-large"}
              lineHeight={"2em"}
            >
              <Text>
                We offer both remote and on-site support through our help desk
                platform TechResQ.
              </Text>
              <Text className={"text-start mt-5"}>TechResQ offers :</Text>
              <ul className={"text-start list-disc mx-10"}>
                <li className={""}>Web based Ticketing System</li>
                <li>User Dashboard</li>
                <li>Ticket Tracker</li>
                <li>Invoicing</li>
                <li>Online Payment (support all SA banks)</li>
              </ul>
              <Text className={"my-5"}>
                This platform is a great product to outsource your IT operations
                while you focus on building your business.
              </Text>
              <Link className={"m-1"} to={"https://www.techresq.co.za"}>
                <Button
                  className={"text-white-800"}
                  _hover={{ bgColor: "#D9D9D9", color: "blue.900" }}
                  bgColor={"whitesmoke"}
                  border={"1px solid whitesmoke"}
                  borderRadius={0}
                >
                  Visit TechResQ
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        bgColor={"whitesmoke"}
        className={"p-10 m-auto"}
        color={"black"}
        id={"partners"}
      >
        <Box>
          <Text className={"text-2xl "}>Partners</Text>
          <Text className={"text-xl m-2"}>
            We have partnered with world class leading IT organizations to
            ensure that our services are always industry relevant and
            competitive.
          </Text>
          <Box className={"md:m-20 m-2 relative"}>
            <Carousel
              additionalTransfrom={0}
              arrow={true}
              autoPlay
              autoPlaySpeed={2000}
              centerMode={false}
              className={"m-10"}
              containerClass={"container-with-dots"}
              dotListClass={"md:m-20 m-4"}
              draggable={false}
              focusOnSelect={false}
              infinite={true}
              itemClass={"m-2 h-full"}
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={true}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 4,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind={false}
              rewindWithAnimation={true}
              rtl={false}
              shouldResetAutoplay={false}
              showDots={false}
              sliderClass={"m-3"}
              slidesToSlide={2}
              swipeable
            >
              <Img
                src={ciscologo}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
              <Img
                src={mtn}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />

              <Img
                src={CRSlogo}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />

              <Img
                src={huawei}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
              <Img
                src={ControlLogo}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />

              <Img
                src={fortnet}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
              <Img
                src={zoom}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
              <Img
                src={Crestronlogo}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
              <Img
                src={kathealogo}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
              <Img
                src={liquidtelecom}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
              <Img
                src={dellemclogohd}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
              <Img
                src={polycom}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
              <Img
                src={tod}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
              <Img
                src={axiz}
                display={"block"}
                h={"150px"}
                margin={"auto"}
                w={"100%"}
              />
            </Carousel>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default HomePage;
