import {
  Box,
  Center,
  Img,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import gal1 from "./Assets/gal1.jpg";
import gal2 from "./Assets/gal2.jpg";
import gal3 from "./Assets/gal3.jpg";
import gal4 from "./Assets/gal4.jpg";
import gal5 from "./Assets/gal5.jpg";
import gal6 from "./Assets/gal6.jpg";
import gal7 from "./Assets/gal7.jpg";
import gal8 from "./Assets/gal8.jpg";
import gal9 from "./Assets/gal9.jpg";
import gal10 from "./Assets/gal10.jpg";
import gal11 from "./Assets/gal11.jpg";
import gal12 from "./Assets/gal12.jpg";
import polyimage1 from "./Assets/polyimage1.png";
import polyimage2 from "./Assets/polyimage2.jpg";
import polyimage3 from "./Assets/polyimage3.jpg";
import polyimage4 from "./Assets/polyimage4.jpg";
import school1_1 from "./Assets/school1_1.jpg";
import school1_2 from "./Assets/school1_2.jpg";
import school1_3 from "./Assets/school1_3.jpg";
import school1_4 from "./Assets/school1_4.jpg";
import bgImage from "./Assets/bgFrame.jpg";
import { Parallax } from "react-parallax";
import land1 from "./Assets/1363031084-huge.jpg";
import { Link } from "react-router-dom";
import footer_logo from "./Assets/logo_footer.png";
import DepartmentOFSD from "./Assets/Department_of_Social_Development_(South_Africa)_logo.svg.png";

const images = [
  {
    original: gal2,
    thumbnail: gal2,
    description: "Collaboration & Communication Services ",
  },
  {
    original: gal3,
    thumbnail: gal3,
    description: "Collaboration & Communication Services ",
  },
  {
    original: gal4,
    thumbnail: gal4,
    description: "Collaboration & Communication Services ",
  },
  {
    original: gal6,
    thumbnail: gal6,
    description: "Collaboration & Communication Services ",
  },
  {
    original: gal7,
    thumbnail: gal7,
    description: "Collaboration & Communication Services ",
  },
  {
    original: gal9,
    thumbnail: gal9,
    description: "Collaboration & Communication Services ",
  },
  {
    original: gal10,
    thumbnail: gal10,
    description: "Collaboration & Communication Services ",
  },
  {
    original: gal12,
    thumbnail: gal12,
    description: "Collaboration & Communication Services ",
  },
];
const polyImages = [
  {
    original: polyimage1,
    thumbnail: polyimage1,
  },
  {
    original: polyimage2,
    thumbnail: polyimage2,
  },
  {
    original: polyimage3,
    thumbnail: polyimage3,
  },
  {
    original: polyimage4,
    thumbnail: polyimage4,
  },
];
const schoolImages = [
  {
    original: school1_1,
    thumbnail: school1_1,
  },
  {
    original: school1_2,
    thumbnail: school1_2,
  },
  {
    original: school1_3,
    thumbnail: school1_3,
  },
  {
    original: school1_4,
    thumbnail: school1_4,
  },
];
const audioAndVisual = [
  {
    original: gal8,
    thumbnail: gal8,
  },
  {
    original: gal11,
    thumbnail: gal11,
  },
  {
    original: gal5,
    thumbnail: gal5,
  },
  {
    original: gal1,
    thumbnail: gal1,
  },
];

function Gallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box color={"white"} bgColor={"#033053"}>
      <NavBar />
      <Parallax
        bgImage={bgImage}
        bgClassName={"object-cover md:object-fill"}
        bgImageAlt={"NdalaICT Landing Page Image"}
        strength={-100}
      >
        <Box className={"h-full"} minH={"80vh"}>
          <Center
            className={"pt-36 pb-10"}
            flexDir={"column"}
            fontFamily={"Roboto"}
          >
            <Text color={"white"} fontWeight={"500"} className={"text-3xl"}>
              Our Gallery
            </Text>
          </Center>
          <Box className={"m-0 p-0 "}>
            <Tabs variant="enclosed" align="center">
              <TabList>
                <Tab
                  _selected={{ color: "#003053", bg: "rgba(224,224,224,0.72)" }}
                >
                  Communication and Collaboration
                </Tab>

                <Tab
                  _selected={{ color: "#003053", bg: "rgba(224,224,224,0.72)" }}
                >
                  Corporate Social Responsibility{" "}
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box>
                    <Tabs isFitted variant="soft-rounded">
                      <TabList>
                        <Tab
                          color={"white"}
                          _selected={{
                            color: "#003053",
                            bg: "rgba(224,224,224,0.72)",
                          }}
                        >
                          VoIP
                        </Tab>
                        <Tab
                          color={"white"}
                          _selected={{
                            color: "#003053",
                            bg: "rgba(224,224,224,0.72)",
                          }}
                        >
                          {" "}
                          Audio And Visual{" "}
                        </Tab>
                        <Tab
                          color={"white"}
                          _selected={{ color: "#003053", bg: "white" }}
                        >
                          Video Conference
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <Box className={"p-0 md:p-8 w-full  h-full m-auto"}>
                            <Box
                              className={"m-2 p-2"}
                              bgColor={"rgba(224,224,224,0.72)"}
                              color={"black"}
                            >
                              <Text className={"text-2xl font-bold m-2"}>
                                Voice Over Internet Protocol
                              </Text>
                            </Box>
                            <Box
                              className={
                                "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4"
                              }
                            >
                              {polyImages.map((image) => (
                                <a
                                  href={image.original}
                                  data-lightbox={"poly"}
                                  style={{ overflow: "hidden", margin: ".2em" }}
                                  className={"relative"}
                                >
                                  <Img
                                    src={image.original}
                                    objectFit={"cover"}
                                    height={"100%"}
                                    data-lightbox={"poly"}
                                    className={"mx-4"}
                                    data-title="My poly gallery"
                                    transition={"all ease 1s "}
                                    _hover={{
                                      transform: "scale(1.1)",
                                      transitions: "all ease 1s ",
                                    }}
                                  />
                                </a>
                              ))}
                            </Box>
                          </Box>
                        </TabPanel>
                        <TabPanel>
                          <Box className={"p-0 md:p-8 w-full  h-full m-auto"}>
                            <Box
                              className={"m-2 p-2"}
                              color={"black"}
                              bgColor={"rgba(224,224,224,0.72)"}
                            >
                              <Text className={"text-2xl m-2 font-bold"}>
                                Audio and Visual
                              </Text>
                            </Box>
                            <Box
                              className={
                                "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4"
                              }
                            >
                              {audioAndVisual.map((image) => (
                                <a
                                  href={image.original}
                                  data-lightbox={"gallery"}
                                  style={{ overflow: "hidden", margin: ".2em" }}
                                  className={"relative"}
                                >
                                  <Img
                                    src={image.original}
                                    data-lightbox={"gallery"}
                                    data-title="My caption"
                                    transition={"all ease 1s "}
                                    _hover={{
                                      transform: "scale(1.1)",
                                      transitions: "all ease 1s ",
                                    }}
                                  />
                                </a>
                              ))}
                            </Box>
                          </Box>
                        </TabPanel>
                        <TabPanel>
                          <Box className={"p-0 md:p-8 w-full  h-full m-auto"}>
                            <Box
                              className={"m-2 p-2"}
                              bgColor={"rgba(224,224,224,0.72)"}
                              color={"black"}
                            >
                              <Text className={"text-2xl m-2  font-bold"}>
                                Video Conference
                              </Text>
                            </Box>
                            <Box
                              className={
                                "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4"
                              }
                            >
                              {images.map((image) => (
                                <a
                                  href={image.original}
                                  data-lightbox={"polygallery"}
                                  style={{ overflow: "hidden", margin: ".2em" }}
                                  className={"relative"}
                                >
                                  <Img
                                    src={image.original}
                                    data-lightbox={"polygallery"}
                                    data-title="Poly images"
                                    transition={"all ease 1s "}
                                    _hover={{
                                      transform: "scale(1.1)",
                                      transitions: "all ease 1s ",
                                    }}
                                  />
                                </a>
                              ))}
                            </Box>
                          </Box>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box className={"p-0 md:p-8 w-full  h-full m-auto"}>
                    <Box
                      className={"m-2 p-2 "}
                      bgColor={"rgba(224,224,224,0.72)"}
                      color={"black"}
                    >
                      <Text className={"text-2xl m-2 font-bold"}>
                        Corporate Social Responsibility{" "}
                      </Text>
                    </Box>
                    <Box
                      className={
                        "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4"
                      }
                    >
                      {schoolImages.map((image) => (
                        <a
                          href={image.original}
                          data-lightbox={"poly_gallery"}
                          style={{ overflow: "hidden", margin: ".2em" }}
                          className={"relative"}
                        >
                          <Img
                            src={image.original}
                            height={"100%"}
                            objectFit={"cover"}
                            data-lightbox={"poly_gallery"}
                            data-title="My poly project"
                            transition={"all ease 1s "}
                            _hover={{
                              transform: "scale(1.1)",
                              transitions: "all ease 1s ",
                            }}
                          />
                        </a>
                      ))}
                    </Box>
                    <Box
                      className={"w-full flex flex-col backdrop-blur-md p-6"}
                    >
                      <Box
                        className={
                          "flex flex-col md:flex-row md:m-auto mx-auto p-3 "
                        }
                        backdropBlur={"5"}
                      >
                        <Box className={"w-full"}>
                          <Img
                            className={"w-1/2 h-2/3 mx-auto md:m-auto"}
                            src={footer_logo}
                          ></Img>
                        </Box>
                        <Box className={"w-full"}>
                          <Img
                            className={"w-1/2 h-2/3 m-auto"}
                            src={DepartmentOFSD}
                          ></Img>
                        </Box>
                      </Box>
                      <Box
                        className={
                          "flex flex-col gap-3 justify-center align-middle w-full"
                        }
                      >
                        <Text className={"text-md"}>
                          Ndala ICT Solutions is pleased to announce a
                          collaboration effort with the Department of Social
                          Development to donate computers to Mqakana S.S in Port
                          St Johns ,Eastern Cape.
                          <br></br>
                          This event took place on the 25th August,2023, and
                          represents a significant step towards ehancing
                          educational resources in an underserved community.
                        </Text>
                        <Link
                          target={"#"}
                          to={
                            "https://x.com/NdalaICT/status/1695016245572038905?s=20"
                          }
                          className={"text-md font-bold "}
                        >
                          {">>Read more..<<"}
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </Parallax>
      <Footer />
    </Box>
  );
}

export default Gallery;
