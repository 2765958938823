import {
  Box,
  Center,
  Flex,
  Img,
  ListItem,
  Tag,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import laptopsales from "../Assets/nikolay-ep6Afz45gH0-unsplash.jpg";
import itsales from "../Assets/network-4393372_1280.jpg";
import handleScroller from "./scrollHandler";

function Hardware_Software() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <NavBar />
      <Box className={"pt-20"}>
        <Center
          className={"pt-20 pb-10"}
          flexDir={"column"}
          fontFamily={"Roboto"}
          bgColor={"#033053"}
        >
          <Text color={"white"} fontWeight={"500"} className={"text-3xl"}>
            Hardware and Software Sales{" "}
          </Text>
          <Flex>
            <Tag
              cursor={"pointer"}
              onClick={() => handleScroller("laptop_&_desktop")}
              className="p-2 m-2"
            >
              Laptops & Desktops
            </Tag>
            <Tag
              cursor={"pointer"}
              onClick={() => handleScroller("net_equipment")}
              className="p-2 m-2"
            >
              Network equipment
            </Tag>
          </Flex>
        </Center>
        <Box color={"blackAlpha.800"}>
          <Box
            id="laptop_&_desktop"
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32"
            }
          >
            <Box className={"p-3 text-start"} fontFamily={"Roboto Condensed"}>
              <Text className={"text-2xl text-bold"}>
                Laptop & Desktop Sales
              </Text>
              <Text className="p-2">
                We offer a wide range of top-quality laptops and desktops to fit
                any business needs, from powerful workstations for graphic
                design and video editing to sleek, lightweight models for
                on-the-go productivity. Our selection includes trusted brands
                such as Dell, HP, and Lenovo, and we are committed to providing
                the latest technology at competitive prices.
              </Text>

              <Text className="p-2">
                But our services don't stop there. Our team of experienced
                technicians will handle the entire installation process,
                ensuring that your new equipment is set up and running smoothly.
                We understand that time is money in the business world, which is
                why we work quickly and efficiently to minimize any disruption
                to your operations.
              </Text>

              <Text className="p-2">
                In addition, we offer comprehensive technical support and
                maintenance services to keep your equipment running smoothly for
                years to come. Whether you need software updates, hardware
                repairs, or general troubleshooting, our team is always
                available to provide expert assistance.
              </Text>

              <Text className="p-2">
                Investing in reliable technology is essential for any business
                to thrive in today's fast-paced market. Trust us to provide the
                best quality equipment and installation services to help your
                business stay ahead of the curve.
              </Text>
            </Box>
            <Box className={"p-3"}>
              <Img src={laptopsales} />
            </Box>
          </Box>
          <Box
            id="net_equipment"
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pb-32 pt-32"
            }
          >
            <Box className={"p-3 text-start"} fontFamily={"Roboto Condensed"}>
              <Text className={"text-2xl text-bold"}>Network Equipment</Text>
              <Text className="p-2">
                We understand the importance of having a reliable and robust
                network infrastructure in today's business world. With our
                cutting-edge equipment and expert technicians, we provide
                comprehensive networking solutions tailored to meet your
                specific needs and requirements.
              </Text>
              <Text className="p-2">
                Our Network Equipment Sales and Installation Setup service
                includes the following:
              </Text>
              <UnorderedList className={"mx-10"}>
                <ListItem>
                  <Text className="p-2">
                    High-quality network equipment sales from leading
                    manufacturers
                  </Text>
                </ListItem>
                <ListItem>
                  <Text className="p-2">
                    Professional installation and configuration of network
                    equipment
                  </Text>
                </ListItem>
                <ListItem>
                  <Text className="p-2">
                    Ongoing maintenance and support to ensure optimal
                    performance and reliability
                  </Text>
                </ListItem>
                <ListItem>
                  <Text className="p-2">
                    Customized network design and planning services to maximize
                    efficiency and scalability
                  </Text>
                </ListItem>
              </UnorderedList>
              <Text className="p-2">
                We take a consultative approach to every project, starting with
                a thorough analysis of your current network infrastructure and
                your unique business needs. Our team of experienced technicians
                then works closely with you to design and implement a custom
                network solution that meets your specific goals and objectives.
              </Text>

              <Text className="p-2">
                Whether you need to upgrade your existing network or set up a
                completely new one, we have the expertise and resources to get
                the job done right. Our comprehensive solutions are designed to
                provide you with a reliable and secure network infrastructure
                that will keep your business running smoothly and efficiently.
              </Text>
            </Box>
            <Box className={"p-3"} fontFamily={"Roboto Condensed"}>
              <Img src={itsales} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default Hardware_Software;
