import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Icon,
  Box,
  Center,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { BiWindows } from "react-icons/bi";
import {
  IoCallSharp,
  IoCellular,
  IoChatbox,
  IoChevronForward,
  IoDesktopSharp,
  IoDocument,
  IoHeadset,
  IoHome,
  IoPeople,
  IoPrintOutline,
  IoRadio,
  IoRepeatSharp,
  IoShieldCheckmark,
  IoWifi,
} from "react-icons/io5";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import { Parallax } from "react-parallax";
import servers from "./Assets/servers.jpg";

const serviceList = [
  {
    category: "IT Support Services",
    services: [
      {
        icon: IoCallSharp,
        name: "End-user Support (Remote & Onsite)",
        items: ["IPPBX", "Hosted IPPBX"],
      },
      {
        icon: IoChatbox,
        name: "Messaging Services ",
        items: ["Email Services", "Unified Messaging", "Bulk SMS", "Live Chat"],
      },
      {
        icon: IoDocument,
        name: "Fax Services",
        items: ["Cloud Fax"],
      },
      {
        icon: IoPeople,
        name: "Conferencing",
        items: ["Video Conferencing", "Voice Conferencing"],
      },
      {
        icon: IoHeadset,
        name: "Contact Centre Solutions",
        items: [
          "Skill-Based Call Routing",
          "Omni-channel Solutions",
          "Workforce Optimisation",
          " Predictive Dialer",
        ],
      },
      {
        icon: IoRadio,
        name: "Telecommunications Management",
        items: ["Telecommunications Expense Management"],
      },
    ],
  },
  {
    category: "Networking",
    services: [
      {
        icon: IoCellular,
        name: "Internet Connectivity",
        items: ["Fibre", "Wireless( LTE/ 5G)"],
      },
      {
        icon: IoRepeatSharp,
        name: "Data Cabling",
        items: ["Structured Voice and Data Cabling"],
      },
      {
        icon: IoWifi,
        name: "Internet Access",
        items: ["Broadband", "Enterprise", "ADSL"],
      },
    ],
  },
  {
    category: "Managed Services",
    services: [
      {
        icon: IoHome,
        name: "Service Management",
        items: [
          " Single Point of Contact (SPOC) for all incident and service requests",
          " Incident Management, Problem Management, and Change Management",
        ],
      },
      {
        icon: IoDesktopSharp,
        name: "Desktop Support",
        items: [
          "Operating System Management",
          " Patch Management, and Desktop Security (Endpoint Protection)",
          "Installation, Move, Add, Change, and Disposal Services (IMACDs)",
        ],
      },
      {
        icon: BiWindows,
        name: "Server Support",
        items: [
          "End-to-end Server Infrastructure Management",
          "Server Infrastructure and Services Monitoring",
        ],
      },
      {
        icon: IoCallSharp,
        name: "Telephony Support",
        items: [
          "End-to-end Telephony Infrastructure Management",
          " Telephony Infrastructure & Service Monitoring",
        ],
      },
      {
        icon: IoCellular,
        name: "Network Support",
        items: [
          " End-to-end Telephony Infrastructure Management",
          "Network Infrastructure & Services Monitoring",
        ],
      },
      {
        icon: IoShieldCheckmark,
        name: "Security Services",
        items: ["Email security", "Web security", "End-point protection"],
      },
      {
        icon: IoPrintOutline,
        name: "Managed Printing Service (MPS)",
        items: ["SLA Management", "Web security", "Expense Management"],
      },
    ],
  },
];

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box color={"white"} fontFamily={"Roboto Condensed"}>
      <NavBar />
      <Parallax strength={-300} bgImage={servers} bgClassName={"object-cover"}>
        <Box h={"100%"}>
          <Center
            className={"pt-48 pb-10"}
            flexDir={"column"}
            fontFamily={"Roboto"}
            bgColor={"#033053"}
          >
            <Text color={"white"} fontWeight={"500"} className={"text-3xl"}>
              Our Services{" "}
            </Text>
          </Center>
          <Box
            className={"grid grid-cols-1 md:grid-cols-3 p-20"}
            bgColor={"rgba(17,75,128,0.84)"}
          >
            {serviceList.map((service) => (
              <Box className={"p-2 m-2"}>
                <Text
                  className={"text-xl"}
                  fontWeight={"500"}
                  fontFamily={"Roboto"}
                >
                  {service.category}
                </Text>
                <Accordion allowToggle>
                  {service.services.map((item) => (
                    <AccordionItem border={"none"}>
                      <AccordionButton
                        bgColor={"#033053"}
                        boxShadow={"lg"}
                        borderRadius={"5px"}
                        my={4}
                        _hover={{ bgColor: "#033053" }}
                      >
                        <Box
                          className={"text-lg"}
                          as="span"
                          flex="1"
                          textAlign="left"
                        >
                          <Flex alignItems={"center"}>
                            <Icon m={2} as={item?.icon} />
                            <Text m={2} p={0}>
                              {item.name}
                            </Text>
                          </Flex>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel
                        pb={0}
                        bgColor={"whiteAlpha.400"}
                        color={"white"}
                      >
                        {item.items.map((i) => (
                          <Flex p={2} alignItems={"center"}>
                            <Icon as={IoChevronForward} />
                            <Text className={"m-0 p-4"}>{i}</Text>
                          </Flex>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </Box>
            ))}
          </Box>
        </Box>
      </Parallax>
      <Footer />
    </Box>
  );
}

export default Services