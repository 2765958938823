import { Box, Center, Img, Tag, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import wifi from "../Assets/icon-1480926_1920.png";
import cabling from "../Assets/pexels-network-159304.jpg";
import connectivity from "../Assets/shutterstock_323153834.jpg";
import handleScroller from "./scrollHandler";

function NetworkingServices() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <NavBar />
      <Box className={"pt-20"} fontFamily={"Roboto Condensed"}>
        <Center
          className={"pt-20 pb-10"}
          flexDir={"column"}
          fontFamily={"Roboto"}
          bgColor={"#033053"}
        >
          <Text color={"white"} fontWeight={"500"} className={"text-3xl"}>
            Networking Services{" "}
          </Text>
          <Box className={"flex m-2 flex-col md:flex-row"}>
            <Box
              as={Tag}
              cursor={"pointer"}
              onClick={() => handleScroller("internet_connectivity")}
              className="p-2 m-2 w-fit "
            >
              Internet Connectivity (Fibre & LTE)
            </Box>
            <Tag
              cursor={"pointer"}
              onClick={() => handleScroller("data_cabling")}
              className="p-2 m-2 w-fit"
            >
              Data Cabling
            </Tag>
            <Tag
              cursor={"pointer"}
              onClick={() => handleScroller("wifi_net_optimization")}
              className="p-2 m-2 w-fit"
            >
              Wi-Fi Network Optimization
            </Tag>
          </Box>
        </Center>
        <Box color={"blackAlpha.800"}>
          <Box
            id={"internet_connectivity"}
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32"
            }
          >
            <Box className={"p-3 text-start"}>
              <Text className={"text-2xl text-bold"}>
                Internet Connectivity (Fibre & LTE){" "}
              </Text>
              <Text className="p-2">
                With Fibre, you can enjoy reliable, high-speed internet that is
                perfect for businesses of all sizes. Whether you're uploading
                large files, video conferencing with clients, or managing
                multiple online applications, Fibre delivers the speed and
                performance you need to stay ahead of the competition.
              </Text>
              <Text className="p-2">
                And with LTE/5G, you can take your business on the go. Whether
                you're traveling between meetings or working remotely, LTE/5G
                ensures that you stay connected no matter where you are. Plus,
                it's easy to set up and use, so you can stay productive no
                matter where your work takes you.
              </Text>

              <Text className="p-2">
                At NDalaICT Solutions, we offer a range of internet connectivity
                solutions to meet the unique needs of your business. From Fibre
                to LTE/5G, we've got you covered with fast, reliable internet
                that is always there when you need it. So why wait? Upgrade your
                internet connectivity today and take your business to the next
                level.
              </Text>
            </Box>
            <Box className={"p-3"}>
              <Img src={connectivity} />
            </Box>
          </Box>
          <Box
            id={"data_cabling"}
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32"
            }
          >
            <Box className={"p-3 text-start"}>
              <Text className={"text-2xl text-bold"}>Data Cabling</Text>
              <Text className="p-2">
                Data cabling is the foundation of a successful network
                infrastructure. It is responsible for connecting all the devices
                and systems within an organization, allowing for seamless
                communication and data transfer. With a well-designed and
                implemented data cabling system, businesses can experience
                faster data speeds, improved network reliability, and increased
                productivity.
              </Text>
              <Text className="p-2">
                At our company, we understand the importance of a solid data
                cabling system. That's why we offer top-quality data cabling
                services that are tailored to meet the specific needs of your
                business. Our team of highly skilled technicians will work
                closely with you to assess your current cabling infrastructure
                and design a custom solution that maximizes efficiency and
                productivity.
              </Text>

              <Text className="p-2">
                Whether you are looking to upgrade your existing cabling system
                or need a brand new installation, our team has the expertise to
                get the job done right. We use only the highest quality
                materials and state-of-the-art equipment to ensure that your
                data cabling system is built to last.
              </Text>

              <Text className="p-2">
                In today's fast-paced business world, having a reliable data
                cabling system is no longer a luxury, but a necessity. Contact
                us today to learn more about our data cabling services and how
                we can help your business stay connected and competitive in the
                digital age.
              </Text>
            </Box>
            <Box className={"p-3"}>
              <Img src={cabling} />
            </Box>
          </Box>
          <Box
            id={"wifi_net_optimization"}
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 bp-32 pt-32"
            }
          >
            <Box className={"p-3 text-start"}>
              <Text className={"text-2xl text-bold"}>
                Wi-Fi Network Optimization
              </Text>
              <Text className="p-2">
                Are you tired of slow Wi-Fi speeds, buffering videos, and
                frustrating internet outages? It's time to optimize your Wi-Fi
                network!
              </Text>

              <Text className="p-2">
                Wi-Fi network optimization is the key to achieving faster
                speeds, reliable connectivity, and improved performance for all
                your devices. With our expert services, we can help you unlock
                the full potential of your Wi-Fi network, so you can enjoy
                seamless streaming, gaming, browsing, and more.
              </Text>

              <Text className="p-2">
                Our team of experienced professionals uses the latest tools and
                techniques to diagnose and optimize your network, from
                optimizing router settings and channel selection to implementing
                Wi-Fi extenders and access points. We'll identify any
                interference, signal strength issues, or other factors that may
                be impacting your Wi-Fi performance, and create a tailored
                solution that works for your unique needs.
              </Text>

              <Text className="p-2">
                Whether you're a small business or a large corporation, we can
                help you achieve faster Wi-Fi speeds, improved network
                reliability, and a better user experience for all your customers
                and employees. With Wi-Fi network optimization, you can reduce
                downtime, increase productivity, and enhance the overall
                performance of your network.
              </Text>

              <Text className="p-2">
                So why wait? Contact us today to learn more about our Wi-Fi
                network optimization services, and experience the benefits of a
                fast, reliable, and optimized Wi-Fi network.
              </Text>
            </Box>
            <Box className={"p-3"}>
              <Img src={wifi} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default NetworkingServices;
