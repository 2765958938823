import {Box, Center,Text} from "@chakra-ui/react";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";

function NoPageFound (){
    return (
       <Box>
           <NavBar/>
           <Center height={'100vh'}>
               <Text>Ooops!!! We could not find the page </Text>
           </Center>
           <Footer/>
       </Box>
    )
}
export default NoPageFound