import { Box, Img, Text } from "@chakra-ui/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo_footer from "../Assets/logo_footer.png";

function Footer() {
  const date = new Date();
  useNavigate();
  return (
    <Box
      id={"footer"}
      bottom={0}
      mb={0}
      bgColor={"#033053"}
      h={"100%"}
      fontFamily={"Roboto Condensed"}
    >
      <Box
        className={
          "grid grid-cols-1 grid-rows-3 md:grid-rows-1 md:grid-cols-3 p-5"
        }
      >
        <Box className={"p-10"}>
          <Text className={"text-2xl"}>ABOUT US </Text>
          <Box className={"text-start my-5"}>
            {" "}
            Ndala ICT Solutions (Pty) Ltd is a 100% black owned, and 30% woman
            owned Qualifying Small Enterprise (QSE) that Provides ICT services
            to medium and large enterprises. Ndala ICT Solutions is a B-BBEE
            Level 1 contributor{" "}
            <Link
              to={"/aboutUs"}
              className={"font-light italic  bg-transparent "}
            >
              read more...
            </Link>
          </Box>

          <Img src={logo_footer} width={"200px"} />
        </Box>
        <Box className={"p-10"}>
          <Box className={"grid grid-cols-2 grid-rows-1"}>
            <Box className={"text-start"}>
              <Text className={"text-lg md:text-2xl"}>Pages</Text>
              <Box className={"my-5"}>
                <Box as={Link} to={"/"}>
                  <Text
                    _hover={{
                      bgColor: "whiteAlpha.300",
                      color: "whiteAlpha.800",
                    }}
                    className={"mx-0 my-2 px-0 py-2 md:m-2 p-0 md:p-2 text-xs"}
                  >
                    Home
                  </Text>
                </Box>
                <Box as={Link} to={"/aboutUs"}>
                  <Text
                    _hover={{
                      bgColor: "whiteAlpha.300",
                      color: "whiteAlpha.800",
                    }}
                    className={"mx-0 my-2 px-0 py-2 md:m-2 p-0 md:p-2 text-xs"}
                  >
                    About Us
                  </Text>
                </Box>

                <Box as={Link} to={"/gallery"}>
                  <Text
                    _hover={{
                      bgColor: "whiteAlpha.300",
                      color: "whiteAlpha.800",
                    }}
                    className={"mx-0 my-2 px-0 py-2 md:m-2 p-0 md:p-2 text-xs"}
                  >
                    Gallery
                  </Text>
                </Box>
                <Box as={Link} to={"/contactUS"}>
                  <Text
                    _hover={{
                      bgColor: "whiteAlpha.300",
                      color: "whiteAlpha.800",
                    }}
                    className={"mx-0 my-2 px-0 py-2 md:m-2 p-0 md:p-2 text-xs"}
                  >
                    Contact Us
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box className={"text-start "}>
              <Text className={"md:text-2xl text-lg"}>Our Services</Text>
              <ul className={"my-5"}>
                {serveiceList.map((serviceItem) => (
                  <li>
                    <Link to={serviceItem.url}>
                      <Text
                        _hover={{
                          bgColor: "whiteAlpha.300",
                          color: "whiteAlpha.800",
                        }}
                        className={
                          "mx-0 my-2 px-0 py-2 md:m-2 p-0 md:p-2 text-xs"
                        }
                      >
                        {serviceItem.name}
                      </Text>
                    </Link>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        </Box>
        <Box className={"p-10"}>
          <iframe
            id={"googleMap"}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d91048.40285809006!2d27.951537152573977!3d-26.039901860566676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9573e5aad6b559%3A0x3c28a608c139bc63!2s5%20Cameron%20Ave%2C%20Bryanston%2C%20Sandton%2C%202191!5e0!3m2!1sen!2sza!4v1685438033952!5m2!1sen!2sza"
            width="100%"
            height="200"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <Box className={"text-start my-2"} fontFamily={"Roboto Condensed"}>
            <Text className={"text-sm"}>5 Cameron Ave</Text>
            <Text className={"text-sm"}>Bryanston, Sandton</Text>
          </Box>
        </Box>
      </Box>
      <hr />
      <Box>
        <Text>Copyright © {date.getFullYear()}. Ndala ICT Solutions</Text>
      </Box>
    </Box>
  );
}

const serveiceList = [
  { url: "/services/itsupport", name: "It Support" },
  { url: "/services/network", name: "Networking " },
  { url: "/services/collaborations", name: "Collaboration & Communication  " },
  { url: "/services/cyber", name: "Cyber Security " },
  { url: "/services/sales", name: "Sales " },
];
export default Footer;
