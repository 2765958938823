import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { IoCall, IoLogoWhatsapp, IoMail } from "react-icons/io5";
import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const isError =
    name === "" && email === "" && message === "" && number === "";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(process.env.NAME);
  return (
    <Box color={"white"} fontFamily={"Roboto Condensed"}>
      <NavBar />
      <Box h={"100%"} className={"pt-10"}>
        <Center
          className={"pt-32 pb-20"}
          flexDir={"column"}
          fontFamily={"Roboto"}
          bgColor={"#033053"}
        >
          <Text color={"white"} fontWeight={"500"} className={"text-3xl"}>
            Get in Touch
          </Text>
          <Text className={"text-white "}>
            Please see our contact details below
          </Text>
        </Center>
        <Box
          color={"gray.700"}
          className={
            "grid py-10 px-4 md:p-10 m-0 h-full grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-2"
          }
        >
          <Box
            className={"h-full p-4 "}
            borderRadius={"md"}
            bgColor={"gray.200"}
          >
            <Box className={"m-4"} fontFamily={"Roboto"}>
              <Text
                fontWeight={"500"}
                fontFamily={"Roboto"}
                className={"text-2xl"}
              >
                Send a Message{" "}
              </Text>
              <Text>
                For any enquiries, please feel free to submit an enquiry.
              </Text>
            </Box>
            <Box className={"p-4"}>
              <form
                action={"http://localhost:300/send-form.php"}
                method={"post"}
              >
                <Flex className={"flex flex-col md:flex-col lg:flex-row "}>
                  <FormControl isRequired className={"m-4"} isValid={isError}>
                    <FormLabel>Name:</FormLabel>
                    <Input
                      border={"1px solid whitesmoke"}
                      bgColor={"whitesmoke"}
                      placeholder={"Full Name"}
                      required
                      type={"text"}
                      fontWeight={"500"}
                      color={"gray.500"}
                      name={"fname"}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired className={"m-4"} isValid={isError}>
                    <FormLabel>Email:</FormLabel>
                    <Input
                      border={"1px solid whitesmoke"}
                      bgColor={"whitesmoke"}
                      placeholder={"email"}
                      required
                      type={"email"}
                      fontWeight={"500"}
                      name={"email"}
                      color={"gray.500"}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired className={"m-4"} isValid={isError}>
                    <FormLabel>Number:</FormLabel>
                    <Input
                      border={"1px solid whitesmoke"}
                      bgColor={"whitesmoke"}
                      required
                      placeholder={"Phone Number"}
                      type={"tel"}
                      name={"number"}
                      fontWeight={"500"}
                      color={"gray.500"}
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </FormControl>
                </Flex>
                <Box>
                  <FormControl isRequired className={"m-4"} isValid={isError}>
                    <FormLabel>Message:</FormLabel>
                    <Textarea
                      border={"1px solid whitesmoke"}
                      bgColor={"whitesmoke"}
                      placeholder={"Message"}
                      required
                      type={"text"}
                      fontWeight={"500"}
                      color={"gray.500"}
                      value={message}
                      name={"message"}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </FormControl>
                </Box>
                <Box className={"mr-auto w-full"}>
                  <Button className={"mr-auto w-auto"} type={"submit"}>
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
          <Box className={"p-8"} pos={"relative"}>
            <iframe
              title={"NdalaICT Solutions Address"}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d91048.40285809006!2d27.951537152573977!3d-26.039901860566676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9573e5aad6b559%3A0x3c28a608c139bc63!2s5%20Cameron%20Ave%2C%20Bryanston%2C%20Sandton%2C%202191!5e0!3m2!1sen!2sza!4v1685438033952!5m2!1sen!2sza"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
            ></iframe>
            <Flex flexDir={"row"} className={"m-2 p-2"}>
              <Box>
                <Link to={"mailto:support@ndalaict.com"}>
                  <IconButton
                    aria-label={"email"}
                    size={"sm"}
                    className={"my-1 mr-4"}
                    icon={<IoMail />}
                  />
                </Link>
              </Box>
              <Box>
                <Link to={"tel:+27877025507"}>
                  <IconButton
                    size={"sm"}
                    className={"my-1 mx-4"}
                    icon={<IoCall />}
                    aria-label={"call"}
                  />
                </Link>
              </Box>
              <Box>
                <Link to={"https://wa.me/+27877025507"}>
                  <IconButton
                    size={"sm"}
                    className={"my-1 mx-4"}
                    icon={<IoLogoWhatsapp />}
                    aria-label={"whatsapp"}
                  />
                </Link>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default ContactUs;
