import { IoIosMenu } from "react-icons/io";
import { IoMail } from "react-icons/io5";
import logo_blue from "../Assets/logoBlue.svg";

import { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Img,
} from "@chakra-ui/react";
import { BsTelephoneFill, BsX } from "react-icons/bs";
import "./NavBar.scss";

function NavBar() {
  const navigate = useNavigate();
  const [togglingStateState, settogglingStateState] = useState(false);
  useLayoutEffect(() => {
    if (window.innerWidth < 700) {
      settogglingStateState(true);
    }
  }, []);
  // const handleLink = (url) => {
  //   if (window.innerWidth < 786) {
  //     settogglingStateState(true);
  //     navigate(url);
  //   }
  // };
  return (
    <Box zIndex={100} bgColor={"white"} className={"nav flex w-full"}>
      <Img
        className={"mx-4 md:mx-2"}
        onClick={() => navigate("/")}
        cursor={"pointer"}
        src={logo_blue}
        alt={"ndalaict logo"}
        width={"140px"}
      />
      <Flex className={"navcont"} w={"100%"}>
        <Box className={"flex contacts"}>
          <Box
            as={Link}
            className={"h-auto w-fit"}
            to={"tel:011 026 3252"}
            m={1}
            _hover={{ bgColor: "whiteAlpha.700", borderRadius: "10px" }}
          >
            <Button
              className={"m-0 p-0 w-fit"}
              leftIcon={
                <Icon
                  className={"m-0 p-0"}
                  color={"black"}
                  borderRadius={"50%"}
                  bgColor="white"
                  fontSize={"1.7em"}
                  m={0}
                  p={1}
                  as={BsTelephoneFill}
                />
              }
            >
              <Text className={"hidden sm:block md:block lg:block "}>
                011 026 3252
              </Text>
            </Button>
          </Box>
          <Box
            as={Link}
            m={1}
            _hover={{ bgColor: "whiteAlpha.700", borderRadius: "10px" }}
            to={"mailto: info@ndalaict.com"}
          >
            <Button
              leftIcon={
                <Icon
                  color={"black"}
                  borderRadius={"50%"}
                  bgColor="white"
                  fontSize={"1.7em"}
                  m={1}
                  p={1}
                  as={IoMail}
                />
              }
            >
              <Text className={"hidden sm:block md:block lg:block "}>
                info@ndalaict.com
              </Text>
            </Button>
          </Box>
        </Box>
        <Box className="toggler" pos={"relative"}>
          <IconButton
            onClick={() => settogglingStateState(!togglingStateState)}
            icon={togglingStateState ? <IoIosMenu /> : <BsX />}
            aria-label={"arial-label"}
          ></IconButton>
        </Box>
        <Box
          boxShadow={"md"}
          className={`bg-[#033053] md:bg-white navBody ${
            !togglingStateState ? "" : "closed"
          }`}
          bottom={0}
          pos={"absolute"}
        >
          <Box bgColor="white" className="toggler close"></Box>
          <ul className="navlist">
            <Box className="navlist-inner">
              <li className="m-0 p-0">
                <Box as={Link} to="/">
                  <Text
                    className={"hover:uppercase"}
                    fontFamily="Roboto Condensed"
                  >
                    Home
                  </Text>
                </Box>
              </li>
              <li>
                <Box as={Link} to="/aboutUs">
                  <Text
                    className={"hover:uppercase"}
                    fontFamily="Roboto Condensed"
                  >
                    About Us
                  </Text>
                </Box>
              </li>
              <li className="sub-list">
                <Box as={Link} to="#">
                  <Text
                    className={"hover:uppercase"}
                    fontFamily="Roboto Condensed"
                  >
                    Our Services
                  </Text>
                </Box>
                <ul className="navlist-sub">
                  <li>
                    <Box as={Link} to="/services/itsupport">
                      <Text color="white" fontFamily="Roboto Condensed">
                        IT Support Services{" "}
                      </Text>
                    </Box>
                  </li>
                  <li>
                    <Box as={Link} to="/services/network">
                      <Text color="white" fontFamily="Roboto Condensed">
                        Networking Services
                      </Text>
                    </Box>
                  </li>
                  <li>
                    <Box as={Link} to="/services/collaborations">
                      <Text color="white" fontFamily="Roboto Condensed">
                        Collaboration & Communication Services
                      </Text>
                    </Box>
                  </li>
                  <li>
                    <Box as={Link} to="/services/cyber">
                      <Text color="white" fontFamily="Roboto Condensed">
                        Cyber Security
                      </Text>
                    </Box>
                  </li>
                  <li>
                    <Box as={Link} to="/services/sales">
                      <Text color="white" fontFamily="Roboto Condensed">
                        Hardware & Software Sales
                      </Text>
                    </Box>
                  </li>
                </ul>
              </li>
              <li>
                <Box as={Link} to="/gallery">
                  <Text
                    className={"hover:uppercase"}
                    fontFamily="Roboto Condensed"
                  >
                    Gallery
                  </Text>
                </Box>
              </li>
              <li>
                <Box as={Link} to="/contactUs">
                  <Text
                    className={"hover:uppercase"}
                    fontFamily="Roboto Condensed"
                  >
                    Contact Us
                  </Text>
                </Box>
              </li>
            </Box>
          </ul>
        </Box>
      </Flex>
    </Box>
  );
}

export default NavBar;
