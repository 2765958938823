import { Box, Center, Text, Img, Tag } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import vulnerability from "../Assets/cyber-4610993.jpg";
import pentest from "../Assets/pexels-tima-miroshnichenko-5380664.jpg";
import endpoint from "../Assets/pexels-vulnerability-270700.jpg";
import handleScroller from "./scrollHandler";

function CyberSecurity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <NavBar />
      <Box className={"pt-20"}>
        <Center
          className={"pt-20 pb-10"}
          flexDir={"column"}
          fontFamily={"Roboto"}
          bgColor={"#033053"}
        >
          <Text color={"white"} fontWeight={"500"} className={"text-3xl"}>
            Cyber Security{" "}
          </Text>
          <Box className={"flex flex-col m-2 md:flex-row"}>
            <Tag
              className={"p-2 m-2"}
              cursor={"pointer"}
              onClick={() => handleScroller("endpoint_protection")}
            >
              End-point Protection
            </Tag>
            <Tag
              className={"p-2 m-2"}
              cursor={"pointer"}
              onClick={() => handleScroller("penetration_testing")}
            >
              Penetration Testing
            </Tag>
            <Tag
              className={"p-2 m-2"}
              cursor={"pointer"}
              onClick={() => handleScroller("vulnerability_assessment")}
            >
              Vulnerability Assessment{" "}
            </Tag>
          </Box>
        </Center>
        <Box color={"blackAlpha.800"}>
          <Box
            id={"endpoint_protection"}
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32"
            }
            fontFamily={"Roboto Condensed"}
          >
            <Box className={"p-3 text-start"}>
              <Text className={"text-2xl text-bold"}>End-point Protection</Text>
              <Text className={"p-2"}>
                Are you concerned about the safety and security of your
                company's data? With the increasing number of cyber threats out
                there, it's more important than ever to ensure that your
                end-points are protected. That's where end-point protection
                comes in. End-point protection is a comprehensive security
                solution that helps protect your company's devices from malware,
                viruses, and other cyber threats. It provides a layered approach
                to security, incorporating features like antivirus software,
                firewall protection, and intrusion prevention to safeguard
                against a wide range of threats.
              </Text>
              <Text className={"p-2"}>
                By implementing end-point protection, you can reduce the risk of
                data breaches and other security incidents that can compromise
                your company's sensitive information. With end-point protection,
                you'll have peace of mind knowing that your devices are secure
                and that your data is protected.
              </Text>
              <Text className={"p-2"}>
                At NdalaICT Solutions, we offer top-of-the-line end-point
                protection solutions that are designed to meet the unique needs
                of your business. Our solutions are easy to deploy and manage,
                and they provide comprehensive protection for all of your
                devices, including desktops, laptops, and mobile devices. Don't
                wait until it's too late. Protect your company's data with
                end-point protection from NdalaICT Solutions today. Contact us
                to learn more about our solutions and how they can help
                safeguard your business against cyber threats.
              </Text>
            </Box>
            <Box className={"p-3"}>
              <Img src={endpoint} />
            </Box>
          </Box>
          <Box
            id={"penetration_testing"}
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32"
            }
            fontFamily={"Roboto Condensed"}
          >
            <Box className={"p-3 text-start h-auto overflow-hidden"}>
              <Text className={"text-2xl text-bold"}>Penetration Testing </Text>
              <Box className="overflow-y-auto">
                <Text className="p-2">
                  Is your company's sensitive information at risk of being
                  compromised? If you're not sure, it's time to consider a
                  penetration testing service.
                </Text>

                <Text className="p-2">
                  Penetration testing is a proactive approach to security that
                  helps identify vulnerabilities in your company's network,
                  applications, and systems. By simulating a cyber attack, our
                  penetration testing service can uncover potential weaknesses
                  and provide actionable insights on how to remediate them.
                </Text>

                <Text className="p-2">
                  At NdalaICT Solutions , we offer a comprehensive penetration
                  testing service that is designed to help you identify and
                  address potential security threats before they can be
                  exploited by malicious actors. Our team of experienced
                  security professionals uses a combination of manual and
                  automated testing techniques to identify potential
                  vulnerabilities in your network and applications.
                </Text>

                <Text className="p-2">
                  Our penetration testing service provides a wide range of
                  benefits for your company, including improved security
                  posture, enhanced compliance, and peace of mind knowing that
                  your sensitive data is secure. With our expert guidance, you
                  can rest assured that your company is well-protected against
                  cyber threats.
                </Text>

                <Text className="p-2">
                  At NdalaICT Solutions, we understand that every business has
                  unique security needs, which is why we offer customized
                  penetration testing solutions that are tailored to your
                  specific requirements. Our team works closely with you to
                  understand your business processes and security goals,
                  ensuring that our penetration testing service delivers the
                  insights you need to keep your company secure.
                </Text>

                <Text className="p-2">
                  Don't leave your company's security to chance. Contact
                  NdalaICT Solutions today to learn more about our penetration
                  testing service and how it can help safeguard your business
                  against cyber threats.
                </Text>
              </Box>
            </Box>
            <Box className={"p-3 h-full"}>
              <Img src={pentest} />
            </Box>
          </Box>
          <Box
            id={"vulnerability_assessment"}
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32"
            }
            fontFamily={"Roboto Condensed"}
          >
            <Box className={"p-3 text-start h-auto overflow-hidden"}>
              <Text className={"text-2xl text-bold"}>
                Vulnerability Assessment{" "}
              </Text>
              <Box className="overflow-y-auto">
                <Text className="p-2">
                  A vulnerability assessment solution is a comprehensive
                  approach to identifying weaknesses in a business's IT
                  infrastructure, including hardware, software, and networks. By
                  performing regular vulnerability assessments, businesses can
                  gain valuable insight into their security posture and make
                  informed decisions about where to focus their security
                  efforts.
                </Text>

                <Text className="p-2">
                  With a vulnerability assessment solution, businesses can
                  identify potential vulnerabilities before they can be
                  exploited by cybercriminals. This helps to reduce the risk of
                  data breaches, cyber attacks, and other security incidents
                  that can have a significant impact on a business's operations
                  and reputation.
                </Text>

                <Text className="p-2">
                  But not all vulnerability assessment solutions are created
                  equal. The best solutions provide detailed insights into
                  potential vulnerabilities and offer actionable recommendations
                  for remediation. They should be easy to use and integrate
                  seamlessly into existing IT systems.
                </Text>

                <Text className="p-2">
                  At NdalaICT Solutions, we offer a state-of-the-art
                  vulnerability assessment solution that is designed to meet the
                  unique needs of businesses of all sizes. Our solution combines
                  advanced scanning technology with expert analysis to identify
                  potential vulnerabilities and provide clear recommendations
                  for remediation.
                </Text>

                <Text className="p-2">
                  With our vulnerability assessment solution, you can rest
                  assured that your business is well-protected against potential
                  cyber threats. Contact us today to learn more about our
                  solution and how it can help your business stay secure in
                  today's ever-changing cybersecurity landscape.
                </Text>
              </Box>
            </Box>
            <Box className={"p-3 h-full"}>
              <Img src={vulnerability} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default CyberSecurity;
