import { Box, Center, Img, Tag, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";

import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import enduser from "../Assets/call-center-1015274.jpg";
import infra from "../Assets/network-4393372_1280.jpg";
import handleScroller from "./scrollHandler";

function ITSupportServices() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <NavBar />
      <Box className={"pt-20"} fontFamily={"Roboto Condensed"}>
        <Center
          className={"pt-20 pb-10"}
          flexDir={"column"}
          fontFamily={"Roboto"}
          bgColor={"#033053"}
        >
          <Text color={"white"} fontWeight={"500"} className={"text-3xl"}>
            IT Support Services{" "}
          </Text>
          <Box className={"flex m-2 flex-col md:flex-row"}>
            <Box
              as={Tag}
              cursor={"pointer"}
              onClick={() => handleScroller("enduser")}
              className="p-2 m-2 w-fit text-center"
            >
              End-user (Remote & Onsite)
            </Box>
            <Tag
              cursor={"pointer"}
              onClick={() => handleScroller("it_infrastracture")}
              className="p-2 m-2 w-fit"
            >
              IT Infrastructure Support & Services
            </Tag>
          </Box>
        </Center>
        <Box color={"blackAlpha.800"}>
          <Box
            id="enduser"
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32"
            }
          >
            <Box className={"p-3 text-start"}>
              <Text className={"text-2xl text-bold"}>
                End-user Support (Remote & Onsite)
              </Text>
              <Text className="p-2">
                Are you tired of dealing with technical issues that disrupt your
                productivity? Look no further than our End-user Support Remote
                and Onsite services!
              </Text>
              <Text className="p-2">
                With our remote support, our expert technicians can quickly
                troubleshoot and resolve your technical issues without ever
                leaving their office. This means you get fast and efficient
                support without the hassle of scheduling an onsite visit.
              </Text>
              <Text className="p-2">
                But for those times when an onsite visit is necessary, our team
                is ready to come to you. We understand that some issues require
                a hands-on approach, and our technicians will work with you to
                schedule a convenient time to visit your location and resolve
                the issue on site.
              </Text>
              <Text className="p-2">
                We offer support for a wide range of technical issues, including
                software installation and troubleshooting, network connectivity
                problems, printer issues, and more. Our team has the expertise
                and experience to handle any issue that comes our way, ensuring
                that your systems are always up and running smoothly.
              </Text>
              <Text className="p-2">
                And with our 24/7 support, you can rest easy knowing that help
                is always just a phone call away. Whether you need remote or
                onsite support, our team is ready and waiting to assist you.
              </Text>
              <Text className="p-2">
                Don't let technical issues disrupt your productivity. Contact us
                today to learn more about our End-user Support Remote and Onsite
                services and how we can help keep your systems running smoothly.
              </Text>
            </Box>
            <Box className={"p-3"}>
              <Img src={enduser} />
            </Box>
          </Box>
          <Box
            id="it_infrastracture"
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32 pb-32"
            }
          >
            <Box className={"p-3 p-8 text-start"}>
              <Text className={"text-2xl text-bold"}>
                IT Infrastructure Support & Services
              </Text>
              <Text className="p-2">
                Our IT infrastructure support and services are designed to help
                businesses of all sizes ensure their technology systems are
                running smoothly and efficiently. We understand that downtime
                can be costly, and that's why we're committed to providing you
                with fast, reliable service to minimize any disruption to your
                operations.Our experienced team of IT professionals has the
                knowledge and expertise to provide a wide range of support
                services
              </Text>
              <Text className="p-2">
                Whether you need ongoing support or assistance with a specific
                project, we're here to help. We take a proactive approach to IT
                infrastructure support and services, identifying potential
                issues before they become problems and ensuring your systems are
                up-to-date and secure.
              </Text>
              <Text className="p-2">
                Our team is dedicated to providing personalized service to each
                and every client, and we work closely with you to develop a
                customized support plan that meets your unique needs and budget.
                With our IT infrastructure support and services, you can focus
                on growing your business, while we take care of the technology
                that supports it.
              </Text>
              <Text className="p-2">
                Don't let technology issues hold you back - contact us today to
                learn more about our IT infrastructure support and services and
                how we can help you achieve your business goals.
              </Text>
            </Box>
            <Box className={"p-3"}>
              <Img src={infra} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default ITSupportServices;
