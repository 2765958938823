import "./App.scss";
import { Box, Button, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import ContactUs from "./ContactUs";
import Gallery from "./Gallery";
import HomePage from "./HomePage";
import { Link, Route, Routes } from "react-router-dom";
import NoPageFound from "./NoPageFound";
import AOS from "aos";
import "aos/dist/aos.css";
import Collaboration_Communications from "./servicePages/Collaboration_Communications";
import CyberSecurity from "./servicePages/CyberSecurity";
import Hardware_Software from "./servicePages/Hardware_Software";
import ITSupportServices from "./servicePages/ITSupportServices";
import NetworkingServices from "./servicePages/NetworkingServices";
import Services from "./Services"; // You can also use <link> for styles
import AboutUs from "./AboutUs";
import { BiX } from "react-icons/bi";
import React, { useState } from "react";
import { IoCall, IoChatbubbleSharp, IoMail } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import Lenis from "@studio-freight/lenis";

function App() {
  const [showContacts, setshowContacts] = useState(false);
  AOS.init();
  const lenis = new Lenis();

  lenis.on("scroll", (e) => {
    console.log(e);
  });
  lenis.options.smoothWheel = true;

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);
  return (
    <Box
      className="App"
      bgColor={"whitesmoke"}
      pos={"relative"}
      color={"white"}
    >
      <Routes>
        <Route element={<HomePage />} exact path={"/"} />
        <Route element={<AboutUs />} exact path={"/aboutUs"} />
        <Route element={<ContactUs />} exact path={"/contactUs"} />
        <Route element={<Gallery />} exact path={"/gallery"} />
        <Route element={<Services />} exact path={"/services"} />
        <Route
          element={<ITSupportServices />}
          exact
          path={"/services/itsupport"}
        />
        <Route
          element={<NetworkingServices />}
          exact
          path={"/services/network"}
        />
        <Route
          element={<Collaboration_Communications />}
          exact
          path={"/services/collaborations"}
        />
        <Route element={<CyberSecurity />} exact path={"/services/cyber"} />
        <Route element={<Hardware_Software />} exact path={"/services/sales"} />
        <Route element={<NoPageFound />} path={"*"} />
      </Routes>
      <Box pos={"fixed"} zIndex={100} bottom={0} right={0}>
        <Flex>
          {showContacts ? (
            <Flex flexDir={"column"} justifyContent={"space-around"}>
              <Box>
                <Link to={"tel: +27 87 702 5507"}>
                  <Tooltip label={"Call Us : 087 702 5507"}>
                    <IconButton
                      borderRadius={"50%"}
                      bgColor={"#002542"}
                      color={"white"}
                      _hover={{ bgColor: "white", color: "#033053" }}
                      icon={<IoCall />}
                      aria-label={"call"}
                    />
                  </Tooltip>
                </Link>
              </Box>
              <Box>
                <Link target={"_blank"} to={"https://wa.me/+27877025507"}>
                  <Tooltip label={"Whatsapp Us on 087 702 5507"}>
                    <IconButton
                      borderRadius={"50%"}
                      bgColor={"#002542"}
                      color={"white"}
                      _hover={{ bgColor: "white", color: "#033053" }}
                      icon={<IoLogoWhatsapp />}
                      aria-label={"call"}
                    />
                  </Tooltip>
                </Link>
              </Box>
            </Flex>
          ) : (
            ""
          )}
          <Flex flexDir={"column"}>
            {showContacts ? (
              <Box>
                <Link to={"mailto: info@ndalict.com"}>
                  <Tooltip label={"Email Us on info@ndalaict.com"}>
                    <IconButton
                      borderRadius={"50%"}
                      bgColor={"#002542"}
                      color={"white"}
                      _hover={{ bgColor: "white", color: "#033053" }}
                      onClick={() => setshowContacts(!showContacts)}
                      icon={<IoMail />}
                      aria-label={"call"}
                    />
                  </Tooltip>
                </Link>
              </Box>
            ) : (
              ""
            )}
            <Box className={" m-1 p-6 h-fit "}>
              {!showContacts ? (
                <Button
                  size={"sm"}
                  onClick={() => setshowContacts(!showContacts)}
                  bgColor={"#002542"}
                  color={"white"}
                  _hover={{
                    bgColor: "white",
                    color: "#033053",
                  }}
                  leftIcon={<IoChatbubbleSharp />}
                >
                  <Text className={"hidden md:block lg:block"}>Contact Us</Text>
                </Button>
              ) : (
                <IconButton
                  bgColor={"#002542"}
                  color={"white"}
                  _hover={{ bgColor: "white", color: "#033053" }}
                  boxShadow={"0px 0px 3px 1px #4A5568"}
                  onClick={() => setshowContacts(!showContacts)}
                  borderRadius={"50%"}
                  aria-label={"contact Us"}
                  icon={<BiX />}
                />
              )}
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export default App;
