import { Box, Center, Text, Img, Tag } from "@chakra-ui/react";
import { useEffect } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import audivisual from "../Assets/technology-6701504_1920.jpg";
import videoconferencing from "../Assets/podcast-7779510_1920.png";
import voip from "../Assets/voicemail-4668542_1280.jpg";
import handleScroller from "./scrollHandler";

export default function Collaboration_Communications() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <NavBar />
      <Box className={"pt-20"} fontFamily={"Roboto Condensed"}>
        <Center
          className={"pt-20 pb-10"}
          flexDir={"column"}
          fontFamily={"Roboto"}
          bgColor={"#033053"}
        >
          <Text color={"white"} fontWeight={"500"} className={"text-3xl"}>
            Collaboration & Communication Services
          </Text>
          <Box className={"flex flex-col m-2 md:flex-row"}>
            <Tag
              cursor={"pointer"}
              onClick={() => handleScroller("voip")}
              className="p-2 m-2"
            >
              VoIP
            </Tag>
            <Tag
              cursor={"pointer"}
              onClick={() => handleScroller("audio_visual")}
              className="p-2 m-2"
            >
              Audio & Visual
            </Tag>
            <Tag
              cursor={"pointer"}
              onClick={() => handleScroller("video_conferencing")}
              className="p-2 m-2"
            >
              Video Conferencing{" "}
            </Tag>
          </Box>
        </Center>
        <Box color={"blackAlpha.800"}>
          <Box
            id="voip"
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32"
            }
          >
            <Box className={"p-3 text-start"}>
              <Text className={"text-2xl text-bold"}>
                Voice over Internet Protocol (VoIP)
              </Text>
              <Text className="p-2">
                Unlike traditional phone systems, which can be expensive to
                install and maintain, VoIP requires minimal hardware and
                infrastructure. Plus, many VoIP providers offer flexible pricing
                plans that allow you to pay only for the features and services
                you need.
              </Text>

              <Text className="p-2">
                But cost savings are just the beginning. With VoIP, you can also
                enjoy features like video conferencing, call forwarding, and
                voicemail-to-email transcription. And because VoIP is
                cloud-based, you can access these features from anywhere, making
                it easier than ever to stay connected with your team and your
                clients.
              </Text>

              <Text className="p-2">
                Perhaps most importantly, VoIP is incredibly reliable. With
                traditional phone systems, downtime can be a major issue, and it
                can take hours or even days to get your system back up and
                running. But with VoIP, downtime is rare, and if there is an
                issue, it can typically be resolved quickly and easily.
              </Text>

              <Text className="p-2">
                So if you're looking for a cost-effective, reliable, and
                feature-packed phone system for your business, look no further
                than VoIP. Contact us today to learn more about our VoIP
                services and how they can help you take your business to the
                next level!
              </Text>
            </Box>
            <Box className={"p-3"}>
              <Img src={voip} />
            </Box>
          </Box>
          <Box
            id="audio_visual"
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32"
            }
          >
            <Box className={"p-3 text-start"}>
              <Text className={"text-2xl text-bold"}>Audio & Visual </Text>
              <Text className="p-2">
                Our service offers the latest in audio and visual technology to
                create dynamic and immersive presentations that will keep your
                audience engaged and informed. We understand the importance of
                effective communication in business and strive to provide the
                highest quality service to help you achieve your goals.
              </Text>
              <Text className="p-2">
                With our Audio and Visual Communication service, you can expect:
              </Text>
              <ol>
                <li>
                  <Text className="p-2">
                    High-Quality Audio: Clear and crisp audio is essential for
                    any presentation, and we ensure that your message is
                    delivered loud and clear to your audience.
                  </Text>
                </li>
                <li>
                  <Text className="p-2">
                    Stunning Visuals: Our team of experts uses cutting-edge
                    technology to create visually stunning presentations that
                    leave a lasting impression.
                  </Text>
                </li>
                <li>
                  <Text className="p-2">
                    Interactive Features: We offer a range of interactive
                    features such as live polling, Q&A sessions, and real-time
                    feedback to encourage audience participation and engagement.
                  </Text>
                </li>
                <li>
                  <Text className="p-2">
                    Customizable Solutions: We understand that every business is
                    unique, which is why we offer customizable solutions
                    tailored to your specific needs.
                  </Text>
                </li>
                <li>
                  <Text className="p-2">
                    Hassle-Free Setup: Our team takes care of all the technical
                    details so that you can focus on delivering your message
                    with confidence.
                  </Text>
                </li>
              </ol>

              <Text className="p-2">
                Don't settle for boring and ineffective presentations. Choose
                our Audio and Visual Communication service to take your business
                presentations to the next level. Contact us today to learn more
                and start creating dynamic and engaging presentations that leave
                a lasting impression.
              </Text>
            </Box>
            <Box className={"p-3"}>
              <Img src={audivisual} />
            </Box>
          </Box>
          <Box
            id="video_conferencing"
            className={
              "grid grid-cols-1 md:grid-cols-2 grid-flow-dense px-10 pt-32"
            }
          >
            <Box className={"p-3 text-start"}>
              <Text className={"text-2xl text-bold"}>Video Conferencing</Text>
              <Text className="p-2">
                With the current global landscape, more and more businesses are
                turning to remote work, making effective communication more
                important than ever before. Our Video Conferencing solution
                provides a seamless and secure platform for virtual meetings,
                ensuring that your business stays connected no matter where you
                are.
              </Text>

              <Text className="p-2">
                Our solution offers high-definition video and audio, enabling
                clear and uninterrupted communication with your colleagues,
                clients, and partners. With features such as screen sharing,
                chat, and recording capabilities, collaboration has never been
                easier.
              </Text>

              <Text className="p-2">
                Our platform is also designed with security in mind, utilizing
                end-to-end encryption to protect your confidential discussions
                and data. You can rest assured that your meetings are private
                and secure.
              </Text>

              <Text className="p-2">
                Whether you are a small startup or a large corporation, our
                Video Conferencing solution is the perfect fit for your business
                needs. With easy setup and user-friendly interface, you can
                start hosting virtual meetings in no time.
              </Text>

              <Text className="p-2">
                Don't let distance or remote work hinder your business growth.
              </Text>
            </Box>
            <Box className={"p-3"}>
              <Img src={videoconferencing} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
